.unitItem {
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 6px 0;
}

.clickableArea {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.unitItemNotCompatible {
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 6px 0;
  background-color: #e7e7e7;
  color: #646464;
}

.unitItemHasError {
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 6px 0;
  background-color: #e7e7e7;
  /* color: red; */
  cursor: not-allowed;
}

.unitItemOnboarded {
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 6px 0;
  background-color: #fff4ea;
  color: #3f3f3f;
}

.checkboxWrapper {
  width: 45px;
  text-align: center;
  padding-bottom: 4px;
}

.checkbox {
  color: #fff;
  background-color: #fff;
  border: 1px solid gray;
  border-radius: 4px;
  font-size: 0.8rem;
  padding: 2px 5px;
  width: 24px;
  height: 24px;
}

.checkbox.selected {
  color: #fff;
  background-color: #ff5722;
  border: none;
  font-size: 0.8rem;
  padding: 2px 5px;
  width: 24px;
  height: 24px;
}

.checkbox.isOnboarded {
  color: #fff;
  background-color: #ffd2c8;
  border: none;
  border-radius: 4px;
  font-size: 0.8rem;
  padding: 2px 5px;
  width: 24px;
  height: 24px;
}

.checkbox.allChildNotCompatible {
  color: #bdbdbd;
  background-color: #bdbdbd;
  border: none;
  border-radius: 4px;
  font-size: 0.8rem;
  padding: 2px 5px;
  width: 24px;
  height: 24px;
}

.checkbox.isParent {
  color: #fff;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  font-size: 0.8rem;
  padding: 2px 5px;
  width: 24px;
  height: 24px;
}

.checkbox.partiallySelected {
  color: #fff;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 2px 5px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkboxDot {
  width: 6px;
  height: 6px;
  background-color: #fd7355;
  border-radius: 6px;
}

.itemName {
  flex: 1;
  padding: 0 12px;
  line-height: 1.25rem;
  font-size: 1rem;
}

.itemStat {
  text-align: right;
  font-size: 0.85rem;
  font-weight: 500;
  line-height: 1.2rem;
}

.arrowWrapper {
  width: 45px;
  text-align: center;
}

.statBarWrapper {
  width: 80px;
  height: 4px;
  background-color: lightgray;
  position: relative;
  text-align: right;
  float: right;
}

.notCompatibleText {
  font-size: 0.8rem;
  color: #6f6f6f;
  font-weight: 400;
}

.gptBar {
  height: 4px;
  background-color: skyblue;
  position: absolute;
  left: 0;
}

.ampBar {
  height: 4px;
  background-color: orange;
  position: absolute;
}

.statIndicator {
  background-color: #969696;
  height: 10px;
  width: 1px;
  position: absolute;
  top: -3px;
}

/* Selected Items Table */
.selectedRow {
  border-bottom: 1px solid gray;
  padding: 10px 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectedRowStart {
  display: flex;
  align-items: center;
  justify-content: start;
}

.unitName {
  margin-left: 8px;
  letter-spacing: 0.03em;
  font-size: 1rem;
}

.showMoreItemsBtn {
  font-size: 1rem;
  font-weight: 500;
  padding: 16px 58px;
  width: 100%;
  display: flex;
  color: rgb(77, 124, 252);
  border-top: 1px solid rgb(77, 124, 252);
  border-bottom: none;
  border-left: none;
  border-right: none;
  background-color: #ecf8ff;
  cursor: pointer;
  text-align: left;
}

.showMoreItemsBtn:hover {
  background-color: #ddedf7;
}
