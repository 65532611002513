/**
 * General Page Styles
 */
html {
  /* margin: 0;
  padding: 0;
  color: #ffffff;
  background-color: #10182c; */
}

html body {
  /* font-family: Roboto, "Trebuchet MS", Helvetica, sans-serif;
  font-size: 18px;
  overflow-x: hidden;
  background-color: #fcfdff; */
}

div {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}

ol ol ol {
  list-style-type: upper-alpha;
  counter-reset: "";
}

ol ol ol li:before {
  content: none;
}

ol ol ol li {
  display: list-item;
  counter-increment: item;
  margin-bottom: 0;
}

.landingPage {
  text-align: center;
}

.displayNone {
  display: none;
}

.paragraph {
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 2;
}

span.link {
  cursor: pointer;
  color: white;
  text-decoration: underline;
}

span.link:hover {
  color: #ff5722;
}

.dividerWrapper {
  padding: 20px 0px;
  text-align: center;
}

.displayInlineBlock {
  display: inline-block;
}

.marginAuto {
  margin: auto;
}

.wrapperNoMargin,
.notFoundWrapper,
.innerWrapper,
.wrapperSmallMargin,
.wrapper {
  color: #10182c;
  background-color: #fcfdff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 15px 0px 150px 0px;
  max-width: 1170px;
  margin: 0 auto;
  min-height: 850px;
}

.wrapperSmallMargin {
  padding: 15px 0px 70px 0px;
  min-height: 0px;
}

.innerWrapper {
  padding: 0px 10% 0px 10%;
}

.wrapperNoMargin > div {
  margin-left: 0px;
  margin-right: 0px;
}

.wrapperNoMargin {
  padding: 15px 0px 150px 0px;
}

.notFoundWrapper {
  text-align: center;
}

.mainTitle {
  padding-top: 100px;
  text-align: center;
  font-size: 50px;
  line-height: 70px;
  font-weight: bold;
  font-family: Lato, "Trebuchet MS", Helvetica, sans-serif;
  letter-spacing: 3px;
}

.textWarning {
  color: #ffc107;
}

.textSuccess {
  color: #28a745;
}

.textDanger {
  color: #dc3545;
}

.textMuted {
  color: #868e96;
}

.textAlignCenter {
  text-align: center;
}

.textAlignRight {
  text-align: right;
}

.textAlignJustify {
  text-align: justify;
}

.textAlignLeft {
  text-align: left;
}

.textAlignJustify {
  text-align: justify;
}

.textView {
  padding: 20px 0px;
  text-align: center;
  font-size: 20px;
}

.pageTitle {
  padding: 5px 15px;
  font-size: 50px;
  font-weight: bold;
  line-height: 60px;
  font-family: Lato, "Trebuchet MS", Helvetica, sans-serif;
  letter-spacing: 3px;
}

.headerWrapper {
  z-index: 1000;
  background-color: #10182c;
}

.headerWrapper .header {
  color: white;
  position: relative;
  height: 66px;
  max-width: 1170px;
  margin: 0px auto;
}

.headerWrapper a {
  color: white;
  transition: color 0.5s;
}

.headerWrapper .selectedNav a {
  color: #ff5722;
}

.headerWrapper .userInfoWrapper {
  right: 20px;
  top: 20px;
  position: absolute;
}

.headerWrapper .userInfoWrapper > * {
  margin-top: 3px;
  margin-left: 5px;
  margin-right: 5px;
}

.headerWrapper .userInfoWrapper i {
  font-size: 22px;
  cursor: pointer;
  transition: color 0.2s;
}

.headerWrapper .userInfoWrapper i:hover {
  color: #ff5722;
}

.headerWrapper .userInfoWrapper a:hover {
  color: #ff5722;
  opacity: 0.8;
}

.footerWrapper {
  position: relative;
  text-align: center;
  color: white;
  height: 175px;
  background-color: #10182c;
  font-weight: 300;
  font-size: 14px;
  font-family: "Lato";
}

.footerWrapper a {
  color: white;
  text-decoration: underline;
  transition: color 0.5s;
}

.footerWrapper a:hover {
  color: #ff5722;
}

.footerWrapper img {
  margin: 15px;
}

.verticalDivider {
  color: #ff5722;
  padding: 0px 10px;
}

.viewPlaceholder {
  width: 100%;
  padding: 200px 0px;
  text-align: center;
}

.headerWrapper .header > a > img {
  cursor: pointer;
  margin: 18px;
}

.buttonLink,
.buttonLinkLight,
.buttonPrimary,
.buttonDanger,
.buttonSuccess,
.buttonNoBorder,
.buttonBig,
.button {
  text-align: center;
  margin-top: 2px;
  display: inline-block;
  font-size: 16px;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
  color: white;
  background-color: #333333;
  padding: 5px 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 0;
  box-sizing: border-box;
  transition: background-color 0.5s, color 0.5s;
}

.buttonBig {
  font-size: 18px;
  padding: 10px;
}

.buttonNoBorder {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

.buttonPrimary {
  background-color: #ff5722;
  color: white;
}

.button i {
  min-width: 14px;
}

.buttonLinkLight,
.buttonLink {
  margin: 0;
  padding: 0;
}

.link,
.buttonLinkLight,
.buttonLink {
  border: 0;
  color: #ff5722;
  background-color: transparent;
}

.link:hover,
.buttonLink:hover {
  color: #10182c;
  text-decoration: underline;
}

.buttonLinkLight:hover {
  color: white;
}

.button a {
  color: inherit;
}

.button a:hover {
  color: inherit;
}

.buttonPrimary:disabled,
.buttonPrimary[disabled],
.buttonDanger:disabled,
.buttonDanger[disabled],
.buttonSuccess:disabled,
.buttonSuccess[disabled],
.button:disabled,
.button[disabled] {
  opacity: 0.7;
  pointer-events: none;
}

.button:hover {
  background-color: #ff5722;
  color: white;
}

.buttonDanger:hover {
  background-color: #dc3545;
  color: white;
}

.buttonSuccess:hover {
  background-color: #28a745;
  color: white;
}

.buttonPrimary:hover {
  opacity: 0.8;
}

.roundButton {
  font-size: 18px;
  cursor: pointer;
  margin-top: 30px;
  font-weight: 600;
  letter-spacing: 1.2px;
  color: white;
  transition: all 0.2s;
  background-color: #ff5722;
  border-radius: 25px;
  border-width: 0px;
  padding: 13px 29px;
}

.roundButton:hover {
  background-color: #10182c;
}

.table {
  border: 1px solid #9a9a9a;
  font-size: 21px;
  margin-bottom: 50px;
}

div.tableHeader {
  font-size: 22px;
  margin-right: 0px;
  margin-left: 0px;
  color: #ff5722;
}

div.tableRowNoHover,
div.tableRow {
  border-top: 1px solid #9a9a9a;
  margin-right: 0px;
  margin-left: 0px;
  transition: background-color 0.5s, color 0.5s, opacity 0.5s;
}

div.tableRow:hover {
  background-color: #eeeeee;
  color: #10182c;
}

.tableColumn {
  border-right: 1px solid #9a9a9a;
  padding: 10px;
  text-align: center;
  word-break: break-all;
}

.tableColumn:last-child {
  border-right: 0;
}

.versionWrapper {
  font-size: 12px;
  position: absolute;
  bottom: 0px;
  left: 20px;
}

.cursorPointer {
  cursor: pointer;
}

.accesibleItem {
  display: block;
  align-items: flex-start;
  text-align: left;
  cursor: default;
  color: inherit;
  background-color: inherit;
  box-sizing: border-box;
  padding: 0px;
  border-width: 0px;
  border-style: outset;
  border-color: inherit;
  border-image: initial;
  width: 100%;
}

.stickyWrapper {
  position: relative;
  top: 0px;
  z-index: 10;
}

.problemIndicatorWrapper {
  position: relative;
}

.indicator {
  position: absolute;
  width: 18px;
  height: 18px;
  line-height: 18px;
  color: white;
  background-color: #dc3545;
  border-radius: 18px;
  font-size: 9px;
  text-align: center;
  top: -7px;
  right: -7px;
  pointer-events: none;
}

.highlightedText {
  font-weight: bold;
  color: #ff5722;
}

.divider {
  position: relative;
  bottom: -2px;
  background-image: url(../images/divider-1.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 201px;
  width: 100%;
}

.logo {
  display: inline-block;
  cursor: pointer;
  margin: 18px;
  width: 194px;
  height: 35px;
  background-image: url(../images/Intowow_logo-01.png);
  background-size: cover;
}

.footerLogo {
  display: inline-block;
  margin: 15px;
  width: 56px;
  height: 56px;
  background-image: url(../images/intowow-logo-180x180.png);
  background-size: cover;
}

.scrollUp {
  background-color: #333;
  border-radius: 4px 4px 0 0;
  bottom: 0;
  color: #fff;
  height: 35px;
  position: fixed;
  right: 75px;
  text-align: center;
  text-transform: uppercase;
  width: 48px;
  opacity: 0.9;
  z-index: 100;
  font-size: 23px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out 0s;
}

.scrollUp:hover {
  background-color: #ff5722;
}

.languageWrapper {
  font-size: 14px;
  text-align: left;
  height: 30px;
  padding: 5px 50px;
  background-color: black;
}

.languageWrapper select {
  cursor: pointer;
  color: white;
  background-color: transparent;
  border: 0;
}

.languageWrapper select * {
  color: black;
}

.multipleSelectItemsWrapper {
  max-height: 550px;
  overflow: auto;
}

.multipleSelectOnboardHeader {
  border-top: 1px solid #9a9a9a;
  border-left: 1px solid #9a9a9a;
  border-right: 1px solid #9a9a9a;
}

.selectedOrder {
  color: white;
  background-color: #ff5722;
}

.tableRow.selectedOrder:hover {
  opacity: 0.8;
  color: white;
  background-color: #ff5722;
}

.publisherAccountWrapper {
  display: inline-block;
  font-size: 14px;
  line-height: 27px;
  max-height: 21px;
  overflow: hidden;
}

.termsWrapper button,
.termsWrapper {
  font-size: 12px;
  padding: 0;
}

@media screen and (max-width: 980px) {
  .notFoundWrapper,
  .wrapper {
    padding: 15px 5px 150px 5px;
  }
  .innerWrapper {
    padding: 0px 5px 0px 5px;
  }
}
