.headerSignoutBtn {
  color: #3182ce;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.headerSignoutBtn:hover {
  text-decoration: underline;
}

.headerSwitchBtn {
  color: #3182ce;
  margin-right: 16px;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.headerSwitchBtn:hover {
  text-decoration: underline;
}
