.login-as-list-wrapper {
  border: 1px #d9d9d9 solid;
  border-radius: 6px;
  width: 80%;
}

.login-as-list-header {
  color: #718096;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px #d9d9d9 solid;
}

/* .login-as-list {
  max-height: 300px;
  overflow-y: auto;
} */

.login-as-list-item {
  color: #2d2d2d;
  padding: 12px 24px;
  border-bottom: 1px #d9d9d9 solid;
  font-size: 1.2rem;
  text-align: left;
  cursor: pointer;
}

.login-as-list-item:hover {
  background-color: #f7fafc;
}

.reseller-badge {
  font-size: 12px;
  font-weight: 600;
  color: #c05621;
  background-color: #feebc8;
  padding: 2px 12px;
  border-radius: 12px;
  margin-left: 8px;
}

.reseller-text {
  font-size: 14px;
  color: #718096;
  line-height: 1;
}
